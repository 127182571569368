@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Berkeley";
  src: url("../public/BerkeleyMono.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.noscroll {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.noscroll::-webkit-scrollbar {
  display: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", "Roboto", "Roboto Mono", sans-serif;
  background-color: #1c1b1b;
  color: #fff;
}

button,
a,
input {
  font-family: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

.tippy-content {
  padding: 0 !important;
}

.tippy-box {
  background: transparent !important;
}

@media (max-width: 1200px) {
  :root {
    font-size: 15px;
  }
}
